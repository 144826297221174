import * as React from "react"
import { Link } from "gatsby"
import "../index.css";
import SEO from "../components/SEO";
import { SocialIcon } from "react-social-icons";

const IndexPage = () => {
  return (
    <>
    <SEO title="Gad Ishimwe"/>
    <main className="main" >
      <h1 className="title">Hi there👋! My name is Gad Ishimwe and I'm a Software Engineer and UI/UX designer👨‍💻</h1>
      <h4>This site is still under construction.</h4>
      <p>For more information, you can contact me on <Link to='mailto:info@gadishimwe.com'>info@gadishimwe.com</Link></p>
      <p style={{marginTop: 120}}>Useful links:  <Link to='https://apps.gadishimwe.com/'> -apps</Link></p>
      <div style={{ display: 'flex', justifyContent: 'center', gap: 16, marginTop: 32 }}>
      <SocialIcon url="https://github.com/gadishimwe" bgColor="black" fgColor="white"  />
      <SocialIcon url="https://www.linkedin.com/in/gadishimwe" bgColor="#0A66C2" fgColor="white" />
      <SocialIcon url="https://twitter.com/gad_ishimwe" fgColor="white"  />
      <SocialIcon url="https://www.instagram.com/gad_ishimwe" bgColor="#833AB4" fgColor="white" />
      <SocialIcon url="https://m.facebook.com/gad.ishimwe.7" bgColor="#0165E1" fgColor="white" />
      </div>
    </main>
    </>
  )
}

export default IndexPage
